import Vue from 'vue'
import App from './App'
import {routes} from './router'

import Vuex from 'vuex';
Vue.use(Vuex);

// import 'bootstrap/dist/css/bootstrap.min.css';

import {MdDivider, MdTooltip, MdCheckbox, MdRipple, MdList,  MdToolbar, MdDrawer, MdApp, MdButton, MdContent, MdCard, MdField, MdSnackbar, MdMenu, MdProgress, MdTable } from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(MdDivider);
Vue.use(MdTooltip);
Vue.use(MdCheckbox);
Vue.use(MdRipple);
Vue.use(MdList);
Vue.use(MdToolbar);
Vue.use(MdApp);
Vue.use(MdDrawer);
Vue.use(MdButton);
Vue.use(MdContent);
Vue.use(MdCard);
Vue.use(MdField);
// Vue.use(MdInput);
Vue.use(MdSnackbar);
Vue.use(MdMenu);
Vue.use(MdProgress);
Vue.use(MdTable);
// Vue.use(MdTable);
// Vue.use(MdTableCell);
// Vue.use(MdTableRow);

import store from './store'
import axios from 'axios'

Vue.config.ProductionTip = false;


import VueRouter from "vue-router";
Vue.use(VueRouter)
export const router = new VueRouter({

  scrollBehavior () {
    return { x: 0, y: 0 };
  },

  routes,
  mode: "history",
  history: true,
});

//Обработка случаев несанкционированного доступа
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return
    }
    next('/login')
  }
  else {
    next()
  }
});


Vue.prototype.$http = axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  // router,
  store,
  router,
  watch:{
    '$route' (){
    }
  },
  render: h => h(App),
}).$mount('#app');