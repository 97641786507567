// router.js
// import Vue from 'vue'
// import Router from 'vue-router'

// import store from './store.js'

// Vue.use(Router);


// import {router} from "./main.js"

// import Home from './views/Home';
// import About from './pages/About';

export const routes = [
  {
    path: '/',
    name: 'Главная',
    // component: Home,
    // lazy load
    component: () => import('./views/UserFeels.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    // lazy load
    component: () => import('./views/Login.vue')
  },
  {
    path: '/password',
    // lazy load
    component: () => import('./views/Password.vue')
  },
  {
    name: "profile",
    path: '/profile',
    // lazy load
    component: () => import('./views/Profile.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: "groups",
    path: '/groups',
    // lazy load
    component: () => import('./views/Groups.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "users",
    path: '/users',
    // lazy load
    component: () => import('./views/Users.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "transactions",
    path: '/transactions',
    // lazy load
    component: () => import('./views/Transactions.vue'),
    meta: {
      requiresAuth: true
    }
  },
];

// export default router