<template>
  <div id="app">
    <div class="page-container">
      <md-app md-waterfall md-mode="fixed-last">
        
        <md-app-toolbar class="md-large md-dense md-primary">
          <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
              <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
                <md-icon>menu</md-icon>
              </md-button>
              
              <span class="md-title">Панель</span>
            </div>
            
            <div class="md-toolbar-section-end">
              <md-button md-menu-trigger @click="logout" v-if="isLoggedIn"><md-icon>exit_to_app</md-icon> Выйти</md-button>
            </div>
          </div>
          
          <div class="md-toolbar-row">
            <md-menu md-size="auto" v-if="isLoggedIn" class="top-menu">
              <md-button md-menu-trigger to="/"><md-icon>pie_chart</md-icon> Статистика</md-button>
              <md-button md-menu-trigger to="/users"><md-icon>supervised_user_circle</md-icon> Пользователи</md-button>
              <md-button md-menu-trigger to="/groups"><md-icon>group_work</md-icon> Группы</md-button>
              <md-button md-menu-trigger to="/transactions"><md-icon>monetization_on</md-icon> Сделки</md-button>
            </md-menu>
            
            <md-menu md-size="auto" v-else>
              <md-button md-menu-trigger to="/login">Авторизация</md-button>
              <md-button md-menu-trigger to="/password">Восстановление пароля</md-button>
            </md-menu>
          </div>
        </md-app-toolbar>
        
        <md-app-drawer :md-active.sync="menuVisible">
          <md-toolbar class="md-transparent" md-elevation="0">Меню</md-toolbar>
          
          <md-list>

            <template v-if="isLoggedIn">
              <md-list-item @click="menuVisible = !menuVisible">
                <md-icon>pie_chart</md-icon>
                <span class="md-list-item-text"><a href="/">Статистика</a></span>
              </md-list-item>

              <md-list-item @click="menuVisible = !menuVisible">
                <md-icon>supervised_user_circle</md-icon>
                <span class="md-list-item-text"><router-link to="/users">Пользователи</router-link></span>
              </md-list-item>

              <md-list-item @click="menuVisible = !menuVisible">
                <md-icon>group_work</md-icon>
                <span class="md-list-item-text"><router-link to="/groups">Группы</router-link></span>
              </md-list-item>

              <md-list-item @click="menuVisible = !menuVisible">
                <md-icon>monetization_on</md-icon>
                <span class="md-list-item-text"><router-link to="/transactions">Сделки</router-link></span>
              </md-list-item>
            </template>
            <template v-else>
              <md-list-item @click="menuVisible = !menuVisible">
                <span class="md-list-item-text"><router-link to="/login">Авторизация</router-link></span>
              </md-list-item>
              <md-list-item @click="menuVisible = !menuVisible">
                <span class="md-list-item-text"><router-link to="/password">Восстановление пароля</router-link></span>
              </md-list-item>
            </template>



            <!--<md-list-item @click="menuVisible = !menuVisible">-->
            <!--  <md-icon>email</md-icon>-->
            <!--  <span class="md-list-item-text"><a href="mailto:support@myfeels.ru">Обратная связь</a></span>-->
            <!--</md-list-item>-->
            <!--<md-list-item>-->
            <!--  <md-icon>apps</md-icon>-->
            <!--  <span class="md-list-item-text"><router-link to="/about"><span @click="menuVisible = false">О приложении</span></router-link></span>-->
            <!--</md-list-item>-->
            <!--<md-list-item>-->
            <!--  <md-icon>add</md-icon>-->
            <!--  <span class="md-list-item-text"><router-link to="/add"><span @click="menuVisible = false">Добавить чувства</span></router-link></span>-->
            <!--</md-list-item>-->
            
            
          </md-list>
        </md-app-drawer>
        
        <md-app-content>
          <router-view/>
        </md-app-content>
      
      </md-app>
    </div>
  
  
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menuVisible: false
      }
    },
    computed: {
      // Проверка на авторизованность
      isLoggedIn: function () {
        return this.$store.getters.isLoggedIn
      }
    },
    methods: {
      // Выйти
      logout: function () {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
      }
    },
    // Обработка просроченных токенов
    created: function () {
      this.$http.interceptors.response.use(undefined, function (err) {
        // return new Promise(function (resolve, reject) {
        return new Promise(function () {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch("logout")
          }
          throw err;
        });
      });
    }
  }

</script>

<style lang="scss">
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
  
  .navbar {
    //background-color: #f8f9fa!important;
    padding: .5rem 1rem;
    margin-bottom: 3em;
    font-weight: 300;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #33b5e5 !important;
    
    .md-menu {
      display: flex;
      display: -ms-flexbox;
      -ms-flex-direction: column;
      flex-direction: column;
      list-style: none;
      -ms-flex-direction: row;
      flex-direction: row;
      
      a {
        color: #fff;
        padding: 0.5rem 1rem;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        display: block;
        color: #fff;
        -webkit-transition: .35s;
        transition: .35s;
        
        &.router-link-exact-active {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
    
  }
  
  .md-app {
    /*max-height: 99.9vh;*/
    max-height: 100vh;
    height: 100%;
    min-height: 100vh;
    /*border: 1px solid rgba(#000, .12);*/
  }
  
  // Demo purposes only
  .md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
  }

  .top-menu {
    @media only screen and (max-width: 510px) {
      display: none !important;
    }
  }

</style>
